<template>

  <div style="text-align: center;position: absolute;top: 10%;left: 10%;width: 900%">
    <van-image
        width="320"
        :src="require('../assets/fr2.png')"
        radius="2"
    />
  </div>
  <div style="text-align: center;position: absolute;top: 19%;width: 100%">
    <span class="text">富日物流仓储管理</span>
  </div>
  <div style="margin-top: 45.33333vw">
    <van-form @submit="onSubmit">
      <van-cell-group inset>
        <van-field
            v-model="tel"
            name="tel"
            label="手机号"
            type="tel"
            placeholder="手机号"
            :rules="[{ required: true, message: '请填写手机号'}, { pattern: /^((1[356789][0-9]{1})+\d{8})$/, message: '必须是手机号码格式'}]"
        />
        <van-field
            v-model="verificationCode"
            name="verificationCode"
            center
            clearable
            label="短信验证码"
            placeholder="请输入短信验证码"
            :rules="[{ required: true, message: '请填写验证码' }]"
        >
          <template #button>
            <span class="codeText" @click="sendCode" v-if="!sended">发送验证码</span>

            <van-count-down :time="time" format="ss" @finish="resetSendCode" v-if="sended">
              <template #default="timeData">
                <span class="codeText">已发送</span>
                <span class="block">{{ timeData.seconds }}</span>
              </template>
            </van-count-down>

          </template>
        </van-field>
        <van-field
            v-model="password"
            type="password"
            name="password"
            label="密码"
            placeholder="密码"
            :rules="[{ required: true, message: '请填写密码' }]"
        />
        <van-field
            v-model="vPassword"
            type="password"
            name="vPassword"
            label="再次输入密码"
            placeholder="再次输入密码"
            :rules="[{ required: true, message: '请再次输入密码' }]"
        />
      </van-cell-group>
      <div style="margin: 4.26667vw">
        <van-button round block type="primary" native-type="submit">
          保存
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import {Toast} from 'vant';
import _ from "lodash";
import {router} from "../router";
import md5 from "js-md5";

export default {
  name: 'PasswordChange',
  setup() {
  },
  data() {
    return {
      tel: "",
      password: "",
      vPassword: "",
      verificationCode: "",
      sended: false,
      time: 60 * 1000,
    };
  },
  created() {
    var tel = this.$route.params.tel;
    this.tel = tel;
  },
  methods: {
    onSubmit(values) {
      if (values.password !== values.vPassword) {
        Toast.fail('密码不一致，请重新输入。');
        return;
      }
      values['tel'] = this.tel;
      values['password'] = md5(this.password);
      values['vPassword'] = md5(this.vPassword);
      var me = this;
      var option = {
        portName: 'touch.system.TouchSecurity.changePassword',
        data: values,
        needLoading: true,
        successCallback: (data) => {
          var tel = me.tel;
          this.$dialog.alert({
            title: '',
            message: '修改成功',
          }).then(() => {
            this.$router.replace({
              name: 'login', params: {
                tel: tel
              }
            })
          });
        }
      };
      this.$sapi.callPort(option, '/security/touchChangePassWord')
    },
    sendCode() {
      var tel = this.tel;
      if (_.isEmpty(tel)) {
        Toast('请输入手机号')
        return;
      }
      var option = {
        portName: 'touch.system.TouchSecurity.sendSMSCode',
        data: {'type': 'PASSWORD_CHANGE', tel: tel},
        needLoading: false,
        successCallback: (data) => {
          this.sended = true;
        }
      }
      this.$sapi.callPort(option);
    },
    resetSendCode() {
      this.time = 60 * 1000;
      this.sended = false;
    },
  },
};
</script>

<style>
.codeText {
  display: inline-block;
  color: #fff;
  font-size: 12px;
  text-align: center;
  padding-left: 4px;
  padding-right: 4px;
  background-color: #1989fa;
}
</style>